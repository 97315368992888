import React from "react";
import ARrehman from "../assets/images/majorEvents/AR-Rahman-Done-Events--2--1573566004070_16e5fd7bb68_large.jpg";
import U2 from "../assets/images/majorEvents/u2.webp";
import coldplay from "../assets/images/majorEvents/coldplay.webp";
import jb from "../assets/images/majorEvents/jb-tour-header-1.webp";
import ketty from "../assets/images/majorEvents/ketty.webp";
import gunsnroses from "../assets/images/majorEvents/gunsnroses.webp";
import ed from "../assets/images/majorEvents/ed-.webp";
import lollapalooza from "../assets/images/majorEvents/Lollapalooza-India-1200by667.jpg";

const MajorEvents = () => {
  return (
    <div className="container mx-auto flex flex-col justify-center my-8">
      <h2 className="font-extrabold text-3xl mx-auto">What We Do</h2>
      <section className="body-font">
        <div className="container px-5 pt-24 pb-0 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block relative h-48 rounded overflow-hidden">
                <img
                  alt="justin-bieber"
                  className="object-cover object-center w-full h-full block"
                  src={jb}
                />
              </a>
              <div className="mt-4">
                <h2 className=" title-font text-lg font-bold">Justin Bieber</h2>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block relative h-48 rounded overflow-hidden">
                <img
                  alt="coldplay"
                  className="object-cover object-center w-full h-full block"
                  src={coldplay}
                />
              </a>
              <div className="mt-4">
                <h2 className=" title-font text-lg font-bold">Coldplay</h2>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block relative h-48 rounded overflow-hidden">
                <img
                  alt="katy Perry"
                  className="object-cover object-center w-full h-full block"
                  src={ketty}
                />
              </a>
              <div className="mt-4">
                <h2 className=" title-font text-lg font-bold">Katy Perry</h2>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block relative h-48 rounded overflow-hidden">
                <img
                  alt="Ed Shareen"
                  className="object-cover object-center w-full h-full block"
                  src={ed}
                />
              </a>
              <div className="mt-4">
                <h2 className=" title-font text-lg font-bold">Ed Sheeran</h2>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block relative h-48 rounded overflow-hidden">
                <img
                  alt="Guns n roses"
                  className="object-cover object-center w-full h-full block"
                  src={gunsnroses}
                />
              </a>
              <div className="mt-4">
                <h2 className=" title-font text-lg font-bold">Guns N’ Roses</h2>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block relative h-48 rounded overflow-hidden">
                <img
                  alt="U2"
                  className="object-cover object-center w-full h-full block"
                  src={U2}
                />
              </a>
              <div className="mt-4">
                <h2 className=" title-font text-lg font-bold">U2</h2>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block relative h-48 rounded overflow-hidden">
                <img
                  alt="Lollapalooza"
                  className="object-cover object-center w-full h-full block"
                  src={lollapalooza}
                />
              </a>
              <div className="mt-4">
                <h2 className=" title-font text-lg font-bold">Lollapalooza</h2>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
              <a className="block relative h-48 rounded overflow-hidden">
                <img
                  alt="AR Rehman"
                  className="object-cover object-center w-full h-full block"
                  src={ARrehman}
                />
              </a>
              <div className="mt-4">
                <h2 className="title-font text-lg font-bold">AR Rahman</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MajorEvents;
