import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/16/solid";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logEvent } from "../utils/analytics";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [toast, setToast] = useState({ message: "", type: "", show: false });
  const [loading, setLoading] = useState(false); // Loading state

  const showToast = (message, type) => {
    setToast({ message, type, show: true });
    setTimeout(() => setToast({ ...toast, show: false }), 3000);
  };
  const handleChange = (e) => {
    console.log("firstName: ");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    console.log("handleSubmit");
    logEvent("Contact Us", "Click", "Submit Clicked", 1);

    e.preventDefault();
    setLoading(true);
    const emailMessage = `
    Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Message: ${formData.message}
  `;
    const payload = {
      ...formData,
      email_message: emailMessage,
    };
    try {
      const response = await fetch("https://fnbxapi.atomx.in/atomx/contactus", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (result.success) {
        console.log("true");
        logEvent("Contact us", "Success", "Form Submitted", 1);
        showToast("Message sent successfully!", "success");
        setFormData({ name: "", email: "", phone: "", message: "" });
      } else {
        showToast("Failed to send message. Try again later.", "error");
        logEvent("Contact us", "Failed", "Submit Failed", 1);
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("Something went wrong!", "error");
      logEvent("Contact us", "Failed", error, 1);
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  //     const handleSubmit = async (e) => {
  //       e.preventDefault();
  //       const emailMessage = `
  //   Name: ${formData.name}
  //   Email: ${formData.email}
  //   Phone: ${formData.phone}
  //   Message: ${formData.message}
  //   `;
  //       const payload = {
  //         ...formData,
  //         email_message: emailMessage,
  //       };
  //       try {
  //         const response = await axios.post(
  //           "https://fnbxapi.atomx.in/atomx/contactus",
  //           payload
  //         );

  //         if (response.status === 200) {
  //           alert("Message sent successfully!");
  //           setFormData({ name: "", email: "", phone: "", message: "" });
  //         } else {
  //           alert("Failed to send message. Try again later.");
  //         }
  //       } catch (error) {
  //         console.error("Error:", error);
  //         alert("Something went wrong!");
  //       }
  //     };

  return (
    <div>
      {toast.show && (
        <div
          className={`fixed top-32 right-5 p-4 rounded-lg shadow-lg text-white ${
            toast.type === "success" ? "bg-green-500" : "bg-red-500"
          }`}
        >
          <div className="flex items-center space-x-2">
            {toast.type === "success" ? (
              <CheckCircleIcon className="w-6 h-6" />
            ) : (
              <XCircleIcon className="w-6 h-6" />
            )}
            <p>{toast.message}</p>
          </div>
        </div>
      )}

      <div
        id="contact"
        className="bg-[#FEEBDF] text-black p-0 md:mx-14 lg:p-12 flex flex-col lg:flex-row"
      >
        {/* Contact Info Section (Shown on top below lg breakpoint) */}
        <div className="w-full lg:w-1/2 flex flex-col  justify-start p-4 mb-6 lg:mb-0">
          <h2 className="text-3xl lg:text-5xl font-bold mb-6 text-center lg:text-left">
            Contact Us
          </h2>
          <p className="mb-6 text-black text-center lg:text-left">
            For questions, technical assistance, or collaboration opportunities
            via the contact information provided.
          </p>
          <div className="space-y-4 text-center lg:text-left">
            {/* <div className="flex items-center justify-center lg:justify-start space-x-2">
            <span className="text-red-500">📞</span>
            <p>+123-456-7890</p>
          </div> */}
            <div className="flex items-center justify-center lg:justify-start space-x-2">
              {/* <span className="text-red-500">📧</span> */}
              <Link to="mailto:hello@atomx.in">
                <i className="fa fa-envelope" />
                <span> hello@atomx.in</span>
              </Link>
              {/* <p>hello@reallygreatsite.com</p> */}
            </div>
            <div className="flex items-center justify-center lg:justify-start space-x-2">
              <span className="text-red-500">📍</span>
              <a
                href="https://www.google.com/maps/dir//A52,+Erica+Rowhouse,+Magarpatta,+Hadapsar,+Pune,+Maharashtra+411013/@18.510677,73.8469828,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c1f3707e9e33:0xa85f1c2baa857608!2m2!1d73.9293845!2d18.5106948?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black hover:underline"
              >
                <p>
                  A52, Erica Rowhouse, Magarpatta, Hadapsar, Pune, Maharashtra
                  411013, India
                </p>
              </a>
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div className="w-full lg:w-1/2 p-4">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col space-y-4 bg-[#FEEBDF]"
          >
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 bg-gray-100 border border-1 border-gray-300  text-gray-800 rounded-lg focus:outline-none"
              required
            />
            <div className="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                className="w-full lg:w-1/2 p-3 bg-gray-100 border border-1 border-gray-300  text-gray-800 rounded-lg focus:outline-none"
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleChange}
                className="w-full lg:w-1/2 p-3 bg-gray-100 border border-1 border-gray-300  text-gray-800 rounded-lg focus:outline-none"
              />
            </div>
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              rows="5"
              className="w-full p-3 bg-gray-100 border border-1 border-gray-300  text-gray-800 rounded-lg focus:outline-none"
              required
            ></textarea>
            {/* <button
              type="submit"
              className="w-32 py-2 px-4 z-50 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg mx-auto md:mx-0 focus:outline-none"
            >
              Submit
            </button> */}

            <button
              type="submit"
              className={`w-32 py-2 px-4 z-50 text-white font-semibold rounded-lg ${
                loading ? "bg-gray-400" : "bg-red-500 hover:bg-red-600"
              } focus:outline-none`}
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center justify-center space-x-2">
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span>Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
