import React, { useEffect } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import Home from "../components/home";
import HowItWorks from "../components/howItWorks";
import MajorEvents from "../components/majorEvents";
import OurServices from "../components/ourServices";
import WhatWeDo from "../components/whatWeDo";
import LDA from "../components/lda";
import { useLocation } from "react-router-dom";
import EduX from "../components/eduX";
import ContactUs from "../components/contactUs";
import { logPageView } from "../utils/analytics";

const HomePage = () => {
  const { hash } = useLocation();

  const offset = window.innerWidth < 600 ? 110 : 130; // Define the offset

  useEffect(() => {
    logPageView(
      `${window.location.pathname}${window.location.search}`,
      "Atomx home page"
    );
  }, []);

  const scrollToElement = () => {
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(scrollToElement, 100); // Adjust the delay as needed
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    const handleHashChange = () => {
      setTimeout(scrollToElement, 100); // Adjust the delay as needed
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("load", handleLoad);
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [hash]);

  return (
    <div className="font-openSans bg-[#FEEBDF] box-border text-[#103F5E]">
      <Header />
      <Home />
      <LDA />
      <EduX />
      <HowItWorks />
      <WhatWeDo />
      <OurServices />
      <MajorEvents />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default HomePage;
