import ReactGA4 from "react-ga4";

export const logPageView = (url, title = "") => {
  ReactGA4.send({
    hitType: "pageview",
    page: url,
    title: title,
  });
};

export const logEvent = (category, action, label = "", value = null) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
    value: value,
  });
};
