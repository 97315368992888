import React from "react";
import bookmyshow from "../assets/images/bookmyshowlogo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#E0524F]">
      <div className="bg-gradient-to-r from-[#FEEBDF] via-[#FEEBDF] to-[#FEEBDF] md:h-24 h-8 relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 280"
          className="absolute bottom-0 -mb-px"
        >
          <path
            fill="#E0524F"
            d="M0,224L60,213.3C120,203,240,181,360,192C480,203,600,245,720,240C840,235,960,181,1080,154.7C1200,128,1320,128,1380,128L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container mx-auto flex flex-col justify-center ">
        {/* <h2 className="font-bold text-3xl my-4 text-white mx-auto">
          Contact Us
        </h2>
        <h2 className="font-bold text-2xl my-4 text-white mx-auto">
          <Link to="mailto:hello@atomx.in">
            <i className="fa fa-envelope" />
            <span> hello@atomx.in</span>
          </Link>
        </h2> */}
        <h2 className="font-bold text-base text-white mx-auto">
          © {new Date().getFullYear()} AtomX
        </h2>
        <h2 className="font-bold text-base text-white mx-auto">powered by</h2>
        {/* <div className="flex justify-center">
          <img className="w-[748px] h-[328px]" src={treeStructure} alt="" />
        </div> */}
        <h3 className="flex justify-center mx-auto mb-10 text-white ">
          <img src={bookmyshow} />
        </h3>
      </div>
    </div>
  );
};

export default Footer;
